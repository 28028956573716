import { deleteToken, readToken } from "@app/services/LocalStorageService";
import { doLogout } from "@app/store/slices/authSlice";
import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { notificationController } from "./toastNotification";
import { store } from "../store/store";

export default function axiosSetup(): typeof axios {
  axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_BACKEND_URL}`;
  // axios.defaults.baseURL = "http://localhost:8001";

  axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig<AxiosRequestHeaders>) => {
      // const token = readToken();
      const state = store.getState();
      const token = state.auth.token;

      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["ngrok-skip-browser-warning"] = "any";
      if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      } else {
        config.headers["Content-Type"] = "application/json";
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError<any>) => {
      if (error.message === "Network Error") {
        notificationController.warning(`Network Error, 'Unable to connect`);
      }

      if (error.response && error.response.status === 401) {
        store.dispatch(doLogout());
        deleteToken();
        notificationController.warning(
          `${
            error.response.data.message === "Password is Incorrect!"
              ? "Enter your correct password"
              : error.response.data.message
          }`
        );
      } else if (error.response && error.response.status === 422) {
        if (Array.isArray(error?.response?.data)) {
          return notificationController.error(`Validation Error`);
        } else {
          notificationController.error(`${error?.response?.data?.message[0]}`);
        }
      } else {
        return Promise.reject(error);
      }
    }
  );
  return axios;
}
