import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

const downloadResume = async (path: string) => {
  const fileUrl = `/downloadfile/${path}`;
  axios
    .get(fileUrl, {
      responseType: "blob",
    })
    .then((response) => {
      const downloadUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

const ShowResume = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.close();
    }, 500);

    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    if (slug) {
      downloadResume(slug);
    }
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Redirecting in 5 seconds...</h1>
      <p>
        If you are not redirected, click{" "}
        <a href="https://careers.stspl.com">here</a>.
      </p>
    </div>
  );
};

export default ShowResume;
