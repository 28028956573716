import { useEffect, memo } from "react";
import { Link } from "react-router-dom";
import { doLogout } from "@app/store/slices/authSlice";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { googleLogout } from "@react-oauth/google";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
interface IHeaderProps {
  name: string;
  subName: string;
  onPress: () => void;
  handleSidenavColor: (color: string) => void;
  handleSidenavType: (type: string) => void;
  handleFixedNavbar: (type: boolean) => void;
  placement?: any;
}

export const Header: React.FC<IHeaderProps> = () => {
  const dispatch = useAppDispatch();

  const handleLogOut = () => {
    googleLogout();
    dispatch(doLogout());
  };

  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <div
        className="header-control"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          justifyContent: "end",
        }}
      >
        <div>
          <CountdownTimer />
        </div>
        <Link to="/sign-in" className="btn-sign-in" onClick={handleLogOut}>
          <span style={{ fontWeight: 600 }}>Logout</span>
        </Link>
      </div>
    </>
  );
};
