import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface PermissionsState {
  permissions: string[];
}

const initialState: PermissionsState = {
  permissions: [],
};


export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    },
    clearPermissions: (state) => {
      state.permissions = [];
    },
  },
  
});

export const { setPermissions, clearPermissions } = permissionsSlice.actions;
