import { useState, useEffect, memo } from "react";
import { useCountdown } from "@app/hooks/useCountdown";
import Modal from "antd/es/modal/Modal";
import { Button } from "antd";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { doLogin, extendAccessToken } from "@app/store/slices/authSlice";
import { useAppDispatch } from "@app/hooks/reduxHooks";

const ShowCounter = ({ minutes, seconds }: any) => {
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "2px" }}>
      <HourglassTopIcon sx={{ color: "#8c8c8c" }} />
      <div style={{ color: "#8c8c8c" }}>
        <div>{`${minutes}: ${seconds}`}</div>
      </div>
    </div>
  );
};

const CountdownTimer = () => {
  let time = localStorage.getItem("maximumTimer");
  let maxTime: number = new Date().getTime();
  const dispatch = useAppDispatch();
  if (time) {
    maxTime = parseInt(time);
  }
  const [minutes, seconds] = useCountdown(maxTime);

  const [isExpiryModalOpen, setIsExpiryModalOpen] = useState(false);

  useEffect(() => {
    if (minutes <= 4) {
      setIsExpiryModalOpen(true);
    }
  }, [minutes]);

  const ContinuSession = () => {
    setIsExpiryModalOpen(false);
    const data: any = {};
    dispatch(extendAccessToken(data)).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <ShowCounter minutes={minutes} seconds={seconds} />

      <Modal
        visible={isExpiryModalOpen}
        closable={false}
        centered
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>Your session will expire within <span style={{ color: "red" }}>{`${minutes}: ${seconds}`}</span></span>
            <p>To avoid session timeout, press Ok to continue </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button type="primary" onClick={ContinuSession}>
                Ok
              </Button>
              <Button
                style={{
                  marginLeft: "10px",
                }}
                type="primary"
                onClick={() => setIsExpiryModalOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default memo(CountdownTimer);
