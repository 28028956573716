export const persistToken = (token: string): void => {
  localStorage.setItem("authToken", token);
};

export const readToken = (): string | null => {
  return localStorage.getItem("authToken");
};

export const deleteToken = (): void => localStorage.removeItem("authToken");

export const deletePersistUser = (): void => {
  localStorage.removeItem("persist:auth");
  localStorage.removeItem("persist:permissions")
}
  
