import axios, { AxiosRequestConfig } from "axios";

export interface ILoginResponse {
  data: {
    token: string;
    allowedHeaders: string;
    error: string;
  };
}

export interface ILoginForm {
  userverify: string;
}

export interface ILoginFormWithPassword {
  usernameoremail: string;
  password: string;
}

export enum Status {
  LOADING = "loading",
  ERROR = "error",
  IDLE = "idle",
}

const authService = {
  login: (data: ILoginForm) => {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/user/login",
      headers: {
        Authorization: `Bearer ${data?.userverify}`,
        "Content-Type": "application/json",
      },
    };
    return axios.request(config);
  },
  loginWithPassword: (data: ILoginFormWithPassword) => {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/user/login/auth",
      data: data,
    };
    return axios.request(config);
  },
  extendAccessToken: () => {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/user/extendAccessToken",
    };
    return axios.request(config);
  },
};

export default authService;
